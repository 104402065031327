<template>
  <div>
    <v-container>
      <v-layout row wrap justify-end class="pr-1 mb-1">
        <v-flex xs12 sm4 md4 lg4 class="text-left">
          <h1 class="title light-blue--text text--darken-2 text-uppercase">
            {{ $t("all orders") }}
          </h1>
        </v-flex>
        <v-spacer></v-spacer>

        <v-flex xs12 sm4 md4 lg4 class="text-right">
          <v-btn
            v-show="showCancelOrder"
            outlined
            :color="$store.state.primaryColor"
            small
            left
            @click="cancelledOrders()"
            class="mr-1 mt-1"
            >Cancelled orders
            <v-icon small right>mdi-clipboard-list</v-icon></v-btn
          >
          <v-btn
            outlined
            small
            right
            class="mr-1 mt-1"
            :color="$store.state.secondaryColor"
            v-if="!isChef"
            >{{ $t("view all orders") }} <v-icon right>mdi-eye</v-icon></v-btn
          >
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-between class="mt-2">
        <v-flex xs12 sm4 md4 lg4 class="text-right pa-1">
          <v-text-field
            v-model="search"
            dense
            solo
            placeholder="Search orders"
          ></v-text-field> </v-flex
        ><v-spacer></v-spacer>
        <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="white" @click="getOrders()"
                ><v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("refresh table") }}</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="orderDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="orderDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                in
                solo
              ></v-text-field>
            </template>
            <v-date-picker v-model="orderDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(orderDate);
                  dateFilter();
                "
              >
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-flex>
      </v-layout>
      <!-- cards -->
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 class="text-right mb-4">
          <!--sort buttons-->

          <v-btn
            :color="
              button.selected
                ? $store.state.secondaryColor
                : $store.state.primaryColor
            "
            x-small
            outlined
            class="mx-1 mt-1"
            v-for="button in controlledAccessMenu"
            :key="button.title"
            @click="sortBy(button.action)"
          >
            <span class="caption text-lowercase">{{ button.title }}</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12>
          <v-data-table
            :headers="controlledAccessTable"
            :items="filteredOrders"
            :items-per-page="20"
            :sort-by.sync="sortByDate"
            :sort-desc.sync="sortDesc"
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.orderId`]="{ item }">
              <span class="font-weight-bold">{{ item.orderId }}</span>
            </template>
            <template v-slot:[`item.totalAmount`]="{ item }">
              {{ currencyFormat(item.totalAmount) }}
            </template>
            <template v-slot:[`item.dateCreated`]="{ item }">
              {{ formatDate(item.dateCreated) }}
            </template>

            <template v-slot:[`item.paymentStatus`]="{ item }">
              <span v-if="!isChef && item.status == true">
                <v-btn
                  small
                  text
                  rounded
                  :color="$store.state.secondaryColor"
                  dark
                  v-if="item.paid"
                  style="cursor: context-menu"
                  >{{ $t("paid") }}
                  <v-icon right>mdi-currency-usd</v-icon></v-btn
                >

                <v-btn
                  small
                  text
                  rounded
                  :color="$store.state.primaryColor"
                  dark
                  v-else
                  style="cursor: context-menu"
                  >{{ $t("unpaid") }}
                  <v-icon right>mdi-currency-usd-off</v-icon></v-btn
                >
              </span>
              <span v-else>
                <v-btn
                  small
                  text
                  rounded
                  :color="$store.state.primaryColor"
                  dark
                  style="cursor: context-menu"
                  >{{ $t("refunded") }}
                  <v-icon right>mdi-cash-refund</v-icon></v-btn
                >
              </span>
            </template>

            <template v-slot:[`item.viewOrder`]="{ item }">
              <v-btn
                @click="showOrderedItems(item.orderId)"
                outlined
                small
                right
                :color="$store.state.secondaryColor"
                dark
                >{{ $t("view order") }} <v-icon right>mdi-eye</v-icon></v-btn
              >
            </template>

            <template v-slot:[`item.cancelOrder`]="{ item }">
              <span v-if="showCancelOrder && item.paid == false">
                <v-btn
                  outlined
                  color="red"
                  small
                  left
                  @click="
                    deleteOrderDialogBox(
                      item.orderId,
                      item.table,
                      item.totalAmount
                    )
                  "
                  >{{ $t("cancel order") }}
                  <v-icon small right>mdi-close-circle</v-icon></v-btn
                >
              </span>
              <span v-else-if="showCancelOrder && item.paid == true">
                <v-btn
                  outlined
                  color="red"
                  small
                  left
                  @click="
                    deleteOrderDialogBox(
                      item.orderId,
                      item.table,
                      item.totalAmount
                    )
                  "
                  >{{ $t("refund order") }}
                  <v-icon small right>mdi-cash-refund</v-icon></v-btn
                >
              </span>
            </template>

            <template v-slot:[`item.paymentOptions`]="{ item }">
              <span v-if="!isChef && !item.paid == true">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      :color="$store.state.primaryColor"
                      small
                      left
                      v-bind="attrs"
                      v-on="on"
                      v-show="showPaymentOptions || waiterIsCashier"
                      @click="viewReceipt(item.orderId)"
                      >{{ $t("payment options") }}</v-btn
                    >
                  </template>
                  <v-list dense>
                    <v-list-item v-for="(it, index) in items" :key="index">
                      <v-list-item-icon>
                        <v-icon v-text="it.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        @click="
                          openCashPayementDialog(
                            item.orderId,
                            item.foodItems,
                            item.totalAmount
                          )
                        "
                        style="cursor: pointer"
                        >{{ it.title }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
              <span v-else>
                <v-btn
                  outlined
                  disabled
                  :color="$store.state.primaryColor"
                  small
                  left
                >
                  {{ $t("payment options") }}
                </v-btn>
              </span>
            </template>
            <template v-slot:[`item.servingStatus`]="{ item }">
              <span v-show="!isChef">
                <v-btn color="success" outlined small left v-if="item.cooked">
                  <span class="caption font-weight-bold">{{
                    $t("ready")
                  }}</span>
                </v-btn>

                <v-btn outlined color="error" small left v-else>
                  <span class="caption font-weight-bold">
                    {{ $t("pending") }}</span
                  >
                </v-btn>
              </span>

              <v-flex
                xs4
                sm6
                md4
                lg4
                class="text-right mb-2"
                v-show="markAsReady"
              >
                <v-btn
                  outlined
                  color="success"
                  small
                  left
                  v-if="!item.cooked"
                  @click="foodReadyDialogBox(item.orderId, item.table)"
                >
                  <span class="caption font-weight-bold">
                    {{ $t("serve") }}</span
                  >
                  <v-icon right> mdi-silverware-fork-knife</v-icon>
                </v-btn>
                <v-btn
                  outlined
                  :color="$store.state.secondaryColor"
                  small
                  left
                  v-else
                >
                  <span class="caption font-weight-bold">
                    {{ $t("served") }}</span
                  >
                  <v-icon right> mdi-check-circle-outline</v-icon>
                </v-btn>
              </v-flex>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-dialog v-model="cashDialog" persistent max-width="600px">
        <CashPayment
          :cart="cart"
          :focus="cashDialog"
          :grandTotal="grandTotal"
          :selectedShop="selectedShop"
          @cashDialog="cashDialog = false"
          @clearCartEmit="clearCartEmit"
        ></CashPayment>
      </v-dialog>

      <!-- ############# -->
      <!-- ordered items dialog -->
      <v-dialog v-model="orderedItemsDialog" width="500">
        <v-card>
          <v-toolbar class="text-h5 gradients" dark>{{
            $t("ordered items")
          }}</v-toolbar>
          <v-card-text>
            <v-list three-line v-for="item in orderedItems" :key="item.id">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    <p>{{ item.item.name }}</p>
                    <p class="font-weight-light">
                      {{ currencyFormat(item.price) }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red" text @click="orderedItemsDialog = false">
              {{ $t("close") }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="delDialog" persistent max-width="290">
        <v-card>
          <div class="text-center">
            <v-btn class="my-5" outlined large fab color="red darken-4">
              <v-icon>warning</v-icon>
            </v-btn>
          </div>

          <v-card-text class="text-center">
            <p>{{ $t("payment options") }}Are you sure?</p>
            <p>
              {{ $t("payment options") }} Do you really want to cancel this
              order
              <span class="font-weight-medium">
                {{
                  "OrderID: " +
                  orderId +
                  " Table: " +
                  table +
                  " Total: " +
                  grandTotal
                }} </span
              >?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :color="$store.state.secondaryColor"
              text
              small
              @click="delDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :color="$store.state.primaryColor"
              text
              :loading="DeleteLoading"
              small
              @click="deleteOrder()"
            >
              {{ $t("confirm cancellation") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ################## -->

      <v-dialog v-model="foodReadyDialog" persistent max-width="290">
        <v-card>
          <div class="text-center">
            <v-btn class="my-5" outlined large fab color="red darken-4">
              <v-icon>warning</v-icon>
            </v-btn>
          </div>

          <v-card-text class="text-center">
            <p>{{ $t("are you sure") }}?</p>
            <p>
              <span class="font-weight-medium">
                {{ "OrderID: " + orderId + " Prepared for " + table }}
              </span>
              {{ $t("is ready") }}?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :color="$store.state.secondaryColor"
              text
              small
              @click="foodReadyDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :color="$store.state.primaryColor"
              text
              :loading="foodReadyLoading"
              small
              @click="markFoodReady()"
            >
              {{ $t("confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackSuccessDelete"
        top
        center
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("order cancelled successfully") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessDelete = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorDelete"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("unable to delete Order, Try again later") }}. </span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="red darken-3"
            @click="snackErrorDelete = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackSuccessFoodReady"
        top
        center
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("order updated successfully") }} </span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessFoodReady = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackSuccessOrderPaid"
        top
        center
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span> {{ $t("order paid successfully") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessOrderPaid = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackErrorOrderPaid"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span> {{ $t("unable to pay for Order, Try again later") }}. </span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="red darken-3"
            @click="snackErrorOrderPaid = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackErrorFoodReady"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>
          {{ $t("unable to mark Order as ready, Try again later") }}.
        </span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="red darken-3"
            @click="snackErrorFoodReady = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import jwtdecode from "jwt-decode";
import { digits } from "@/plugins/functions";
import CashPayment from "@/views/shop/CashPayment";
import { format } from "date-fns";
import db from "@/plugins/fb";

export default {
  components: {
    CashPayment,
  },
  data: () => ({
    orderDate: format(new Date(), "yyyy-MM-dd"),
    sortDesc: true,
    sortByDate: "dateCreated",
    cart: [],
    modal: false,
    cashDialog: false,
    orderedItemsDialog: false,
    delDialog: false,
    foodReadyDialog: false,

    selectedShop: "",
    waiterIsCashier: false,
    items: [
      { title: "Cash", icon: "mdi-cash-fast" },
      { title: "Mpesa", icon: "mdi-cellphone" },
      { title: "Credit Card", icon: "mdi-credit-card" },
    ],
    menu: [
      {
        title: "All",
        action: "all",
        selected: true,
        level: [1, 2, 3, 4, 5],
      },
      {
        title: "Pending",
        action: "pending",
        selected: false,
        level: [1, 2, 3, 4, 5],
      },
      {
        title: "Ready",
        action: "ready",
        selected: false,
        level: [1, 2, 3, 4, 5],
      },
      {
        title: "Paid",
        action: "paid",
        selected: false,
        level: [1, 2, 3, 5],
      },
      {
        title: "Unpaid",
        action: "unpaid",
        selected: false,
        level: [1, 2, 3, 5],
      },
    ],
    loading: true,
    DeleteLoading: false,
    foodReadyLoading: false,
    snackSuccessDelete: false,
    snackErrorDelete: false,
    snackErrorFoodReady: false,
    snackSuccessOrderPaid: false,
    snackErrorOrderPaid: false,
    snackSuccessFoodReady: false,
    showPaymentOptions: false,
    isChef: false,
    markAsReady: false,
    showCancelOrder: false,
    search: "",
    busName: "",
    change: "",
    grandTotal: "",
    receipts: [],
    products: [],
    orderedItems: [],
    receipt: false,
    amountPayed: "",
    filteredOrders: [],
    orderId: 0,
    table: "",
  }),
  created() {
    this.checkAccessLevel();
  },
  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },
    controlledAccessMenu() {
      return this.menu.filter((val) => {
        if (val.level.includes(this.userAccess)) {
          return val;
        }
      });
    },
    controlledAccessTable() {
      return this.headers.filter((val) => {
        if (val.level.includes(this.userAccess)) {
          return val;
        }
      });
    },
    headers() {
      return [
        {
          text: this.$t("order number"),
          value: "orderId",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 4, 5],
        },
        {
          text: this.$t("total amount"),
          value: "totalAmount",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 5],
        },
        {
          text: this.$t("table"),
          value: "table",
          class: "blue-grey darken-3 white--text font-weight-regular",

          level: [1, 2, 3, 4, 5],
        },
        {
          text: this.$t("transaction date"),
          value: "dateCreated",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 4, 5],
        },
        {
          text: this.$t("payment status"),
          value: "paymentStatus",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 5],
        },

        {
          text: this.$t("view order"),
          value: "viewOrder",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 4, 5],
        },
        {
          text: this.$t("cancel order"),
          value: "cancelOrder",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2],
        },
        {
          text: this.$t("payment options"),
          value: "paymentOptions",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 5],
        },
        {
          text: this.$t("serving status"),
          value: "servingStatus",
          class: "blue-grey darken-3 white--text font-weight-regular",
          level: [1, 2, 3, 4],
        },
      ];
    },
  },

  mounted() {
    this.getCurrentUser();
    this.getOrders();
    this.getSettings();
  },

  methods: {
    digits,
    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isManager
        ? 2
        : data.accessLevel.isWaiter
        ? 3
        : data.accessLevel.isChef
        ? 4
        : data.accessLevel.isCashier
        ? 5
        : 6;
    },
    getOrders() {
      this.orders = [];
      this.loading = true;
      //if logged in person is chef, only fetch uncooked orders
      if (this.selected && this.isChef == false) {
        db.collection("orders")
          .where("businessId", "==", this.selected)

          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.orders.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.orders.filter(
                (item) =>
                  format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
                  this.$store.state.todaysDate
              );
              this.filteredOrders = filtered;
            } else {
              this.filteredOrders = this.orders;
            }

            //keep products in vuex store

            this.$store.commit("SET_ORDERS", this.orders);
            this.$store.commit("SET_TODAY_DATE", "");
            this.loading = false;
          });
      } else {
        db.collection("orders")
          .where("businessId", "==", this.selected)

          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.orders.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.orders.filter(
                (item) =>
                  format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
                  this.$store.state.todaysDate
              );
              this.filteredOrders = filtered;
            } else {
              this.filteredOrders = this.orders;
            }

            //keep products in vuex store

            this.$store.commit("SET_ORDERS", this.orders);
            this.$store.commit("SET_TODAY_DATE", "");
            this.loading = false;
          });
      }
    },

    //get user: hide payment button if not cashier
    getCurrentUser() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      if (data.accessLevel.isCashier) {
        this.showPaymentOptions = true;
      } else if (data.accessLevel.isManager) {
        this.showCancelOrder = true;
      } else if (data.accessLevel.isChef) {
        this.isChef = true;
        this.markAsReady = true;
      }
    },
    getSettings() {
      db.collection("configurations")
        .doc(this.$store.state.employerId)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            this.waiterIsCashier = snaps.data().waiterIsCashier;
          } else {
            //set false as minumum default
            this.waiterIsCashier = false;
          }
        })
        .catch(() => {
          this.snackErrorConfig = true;
        });
      // ######
    },
    dateFilter() {
      let filtered = this.orders.filter(
        (item) =>
          format(item.dateCreated.toDate(), "yyyy-MM-dd") == this.orderDate
      );
      this.filteredOrders = filtered;
    },

    viewReceipt() {
      this.receipt = true;
    },
    //attach the selected currency to money
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + Number(val).toFixed(2);
    },

    //function to show the confirmation box
    deleteOrderDialogBox(orderId, table, totalAmount) {
      this.table = table;
      this.orderId = orderId;
      this.grandTotal = totalAmount;

      this.delDialog = true;
    },

    //function to actually mark and order as deleted
    deleteOrder() {
      this.DeleteLoading = true;
      //get selected order
      let ordersOfDay = this.orders.filter(
        (item) => item.orderId == this.orderId
      );

      //format new data
      const newOrder = {
        businessId: ordersOfDay[0].businessId,
        cooked: ordersOfDay[0].cooked,
        dateCreated: ordersOfDay[0].dateCreated,
        foodItems: ordersOfDay[0].foodItems,
        orderId: ordersOfDay[0].orderId,
        status: false,
        table: ordersOfDay[0].table,
        totalAmount: ordersOfDay[0].totalAmount,
      };

      db.collection("orders")
        .doc(this.orderId.toString())
        .update(newOrder)
        .then(() => {
          this.DeleteLoading = false;
          this.delDialog = false;
          this.snackSuccessDelete = true;
          this.getOrders();
        })
        .catch(() => {
          this.DeleteLoading = false;
          this.delDialog = false;
          this.snackErrorDelete = true;
        });
    },
    //open food ready dialog confirmation
    foodReadyDialogBox(orderId, table) {
      this.table = table;
      this.orderId = orderId;

      this.foodReadyDialog = true;
    },

    //function used by waiter to mark food as ready
    markFoodReady() {
      this.foodReadyLoading = true;
      //get selected order
      let ordersOfDay = this.orders.filter(
        (item) => item.orderId == this.orderId
      );

      //format new data
      const newOrder = {
        businessId: ordersOfDay[0].businessId,
        cooked: true,
        dateCreated: ordersOfDay[0].dateCreated,
        foodItems: ordersOfDay[0].foodItems,
        orderId: ordersOfDay[0].orderId,
        status: ordersOfDay[0].status,
        table: ordersOfDay[0].table,
        totalAmount: ordersOfDay[0].totalAmount,
      };

      db.collection("orders")
        .doc(this.orderId.toString())
        .update(newOrder)
        .then(() => {
          this.foodReadyLoading = false;
          this.foodReadyDialog = false;
          this.snackSuccessFoodReady = true;
          this.getOrders();
        })
        .catch(() => {
          this.foodReadyLoading = false;
          this.foodReadyDialog = false;
          this.snackErrorFoodReady = true;
        });
    },
    openCashPayementDialog(orderId, foodItems, totalAmount) {
      //clear cart data and totals
      this.cart = [];
      this.grandTotal = "";

      this.orderId = orderId;

      //prepare Items to enable re use of cashpayment component

      this.selectedShop = this.selected;
      this.cart = foodItems;
      this.grandTotal = totalAmount;
      this.cashDialog = true;
    },
    clearCartEmit() {
      //clear cart data and totals
      this.cart = [];
      this.grandTotal = "";
      this.cashDialog = false;

      // add code here to update order to paid for
      //get selected order
      let ordersOfDay = this.orders.filter(
        (item) => item.orderId == this.orderId
      );

      //format new data
      const newOrder = {
        businessId: ordersOfDay[0].businessId,
        cooked: ordersOfDay[0].cooked,
        dateCreated: ordersOfDay[0].dateCreated,
        foodItems: ordersOfDay[0].foodItems,
        orderId: ordersOfDay[0].orderId,
        status: ordersOfDay[0].status,
        table: ordersOfDay[0].table,
        totalAmount: ordersOfDay[0].totalAmount,
        paid: true,
      };

      db.collection("orders")
        .doc(this.orderId.toString())
        .update(newOrder)
        .then(() => {
          this.snackSuccessOrderPaid = true;
          this.getOrders();
        })
        .catch(() => {
          this.snackErrorOrderPaid = true;
        });
    },

    sortBy(prop) {
      var new_array;
      if (prop == "all") {
        this.menu[0].selected = true;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        //get all orders from vuex store
        this.orders = this.$store.state.orders;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      } else if (prop == "pending") {
        this.menu[0].selected = false;
        this.menu[1].selected = true;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.orders = this.$store.state.orders;

        new_array = this.orders.filter((element) => element.cooked == false);

        this.orders = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      } else if (prop == "ready") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = true;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.orders = this.$store.state.orders;

        new_array = this.orders.filter((element) => element.cooked == true);

        this.orders = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      } else if (prop == "paid") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = true;
        this.menu[4].selected = false;
        this.orders = this.$store.state.orders;

        new_array = this.orders.filter((element) => element.paid == true);

        this.orders = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      } else {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = true;
        this.orders = this.$store.state.orders;

        new_array = this.orders.filter((element) => element.paid == false);

        this.orders = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.orders.filter(
            (item) =>
              format(item.dateCreated.toDate(), "yyyy-MM-dd") ==
              this.$store.state.todaysDate
          );
          this.filteredOrders = filtered;
        } else {
          this.filteredOrders = this.orders;
        }
      }
    },

    showOrderedItems(orderId) {
      //get selected order
      let ordersOfDay = this.orders.filter((item) => item.orderId == orderId);

      this.orderedItems = ordersOfDay[0].foodItems;

      this.orderedItemsDialog = true;
    },
    showOrder(orderId) {
      this.$router.push({ name: "ViewOrderDetails", params: { id: orderId } });
    },

    cancelledOrders() {
      this.$router.push({ name: "cancelledOrders" });
    },
    formatDate(val) {
      var date = val.toDate();
      return format(date, "yyyy-MM-dd HH:mm:ss:bb");
    },
  },
};
</script>

<style></style>
